


















































































import { Effect } from "@/model/menu";
import Vue from "vue";
import _ from "lodash";
import store from "../../store/session";
import { TranslateServiceBuilder } from "../../services/Translate/translateServiceBuilder";
import * as constant from "../../common/util/constant";

const translate = TranslateServiceBuilder.Instance()._;

export default Vue.component("Category", {
  components: {
    QSkeleton: () => import("../../components/elements/QSkeleton.vue"),
    QLink: () => import("../../components/elements/QLink.vue"),
  },
  data() {
    return {
      random: 1,
    };
  },
  mounted() {
    store.dispatch("load");
    store.dispatch("fetchMenus").catch((error) => {
      console.error("fetchMenus error", error.message);
      this.$router.push(`/error?message=メニューの初期化に失敗しました。`);
    });
    this.intervalCheckAvaiable();
  },
  destroyed() {
    this.interval && clearInterval(this.interval)
  },
  computed: {
    categories() {
      return store.state.categories;
    },
    categoryGroups() {
      let result = [];
      let category_group_temps = _.cloneDeep(store.state.categoryGroups);

      _.forEach(category_group_temps, (category_group) => {
        if(!this.hasValidCategory(category_group)){
          return;
        }

        if (!category_group.parent_id) {
          category_group.type = 1;

          if (category_group.category_id) {
            let category = this.getCategory(category_group.category_id)
            category_group.name = category.name;
            category_group.type = category.type;
          }

          category_group.link = `/menu/${category_group.category_id}`;

          if (this.hasChild(category_group.id, category_group_temps)) {
            category_group.link = `/category_group/${category_group.id}`;
          }

          result.push(category_group);
        }
      });

      result = _.orderBy(result, ['rank'],['asc']);

      return result;
    },
    effects() {
      // If the restricted menu you ordered has a category, the category will be displayed first.
      // If you order multiple menus with a time limit, they will be displayed in the order of earliest end time > category.
      if (store.state.effects) {
        return _.chain(store.state.effects)
          .orderBy("last_order_time")
          .orderBy((item) => !item.limit_option)
          .groupBy("order_id")
          .value();
      }
      return [];
    },
    effectTitle() {
      this.random; // force recomputed
      return (effect) => {
        return store.getters.effectLimitTitle(effect);
      }
    },
    effectStatus() {
      this.random; // force recomputed
      return (effect: Effect): "prepare" | "active" | "end" => {
        return store.getters.effectStatus(effect);
      }
    },
    isCategoryAvailable: () => (effect: Effect) => {
      // `/menu/${category.id}?effect_id=${effect.id}`
      return store.getters.isEffectAvaiable(effect);
    },
  },
  methods: {
    getCategory(id){
      return _.find(this.categories, (category) => category.id === id);
    },
    hasChild(id, category_group_temps){
      let result = false;

      let child = _.find(category_group_temps, (category_group) => category_group.parent_id === id);

      return !!child;
    },
    hasValidCategory(category_group) {
      if (!category_group.is_display_end_user) {
        return false;
      }

      let result = false;

      if (category_group.category_id) {
        let category = this.getCategory(category_group.category_id);

        if (category) {
          result = true;
        }
      }

      let childs = _.filter(store.state.categoryGroups, (categoryGroup) => categoryGroup.parent_id === category_group.id);

      _.forEach(childs, (child) => {
        result = result ? result : this.hasValidCategory(child);
      });

      return result;
    },
    toCatStr(catId: number) {
      switch (catId) {
        case 1:
          return "";
        case 2:
          return "takeout";
        case 3:
          return "catering";
      }

      return "";
    },
    getMenuNameById(id) {
      const result = _.chain(this.categories)
        .map("menus")
        .flatten()
        .filter({ id: id })
        .value();

      if (result[0]) {
        return result[0]["name"];
      }

      return null;
    },
    intervalCheckAvaiable() {
      this.interval = setInterval(() => {
        this.random = Date.now();
      }, constant.TIME_RE_COMPUTED);
      // 30 * 60
    },
  },
  filters: {
    toStatusLabel(status: boolean) {
      if (!status) {
        return "end";
      }

      return "";
    },
    toStatusText(status: "prepare" | "active" | "end"): string {
      switch (status) {
        case "prepare":
          return translate("hall-0224", "準備中");
        case "active":
          return "";
        case "end":
          return translate("hall-0223", "終了");
      }
    },
  },
});
